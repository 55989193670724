import { TextInput, Text, Grid } from "@mantine/core";

const HolderCard = ({ holderData, GlobalCard, classes }) => {
  return (
    <>
      {holderData.length > 0 && (
        <GlobalCard>
          <Text
            fw={700}
            size="xl"
            component="h3"
            color="#004236"
            mt={0}
            mb={16}
            ta="center"
          >
            Titular
          </Text>
          <Grid>
            <Grid.Col xs={12} md={3}>
              <TextInput
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                label="Nombre"
                value={holderData[0].nombresApellidos}
                disabled={true}
              />
            </Grid.Col>
            <Grid.Col xs={12} md={3}>
              <TextInput
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                label="Documento de identidad"
                value={`${holderData[0].tipoDoc} ${holderData[0].doc}`}
                disabled={true}
              />
            </Grid.Col>
            <Grid.Col xs={12} md={3}>
              <TextInput
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                label="Cargo"
                value={holderData[0].descFuncionario}
                disabled={true}
              />
            </Grid.Col>
            <Grid.Col xs={12} md={3}>
              <TextInput
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                label="Ciudad"
                value={holderData[0].ciudad}
                disabled={true}
              />
            </Grid.Col>
          </Grid>
        </GlobalCard>
      )}
    </>
  );
};

export default HolderCard;
