import { Button, Grid, Radio, TextInput, Flex } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";

const Form = ({
  editData,
  error,
  GlobalCard,
  editForm,
  classes,
  utils,
  handleCancel,
  handleCreateEdit,
}) => {
  return (
    <>
      {(editData || error) && (
        <GlobalCard>
          <Grid>
            <Grid.Col xs={12} md={6}>
              <TextInput
                {...editForm.getInputProps("plaintiffName")}
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                label="Nombre del demandante"
                placeholder="Nombre del demandante"
              />
            </Grid.Col>
            <Grid.Col xs={12} md={6}>
              <TextInput
                {...editForm.getInputProps("id")}
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                label="Número de documento de identidad"
                placeholder="Número de documento de identidad"
              />
            </Grid.Col>
            <Grid.Col xs={12} md={6}>
              <TextInput
                {...editForm.getInputProps("bank")}
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                label="Entidad bancaria"
                placeholder="Nombre de la identidad bancaria"
              />
            </Grid.Col>
            <Grid.Col xs={12} md={6}>
              <TextInput
                {...editForm.getInputProps("bankAccount")}
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                label="Número de cuenta bancaria"
                placeholder="Número de cuenta bancaria"
              />
            </Grid.Col>
            <Grid.Col xs={12} md={6}>
              <TextInput
                {...editForm.getInputProps("filingNumber")}
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                label="Número de radicado del oficio"
                placeholder="Número de radicado del oficio"
              />
            </Grid.Col>
            <Grid.Col xs={12} md={6}>
              <Radio.Group
                {...editForm.getInputProps("isActive")}
                label="Activo"
                fz={16}
                withAsterisk
              >
                <Flex align="center" h={36} justify="space-evenly">
                  <Radio
                    value="yes"
                    label="Sí"
                    className={classes.radioButton}
                  />
                  <Radio
                    value="no"
                    label="No"
                    className={classes.radioButton}
                  />
                </Flex>
              </Radio.Group>
            </Grid.Col>
            <Grid.Col xs={12} md={6}>
              <DatePickerInput
                {...editForm.getInputProps("initialDate")}
                locale={utils.es}
                classNames={{
                  label: classes.labelSecondary,
                  input: classes.input,
                  day: classes.calendar,
                  rightSection: classes.rightSection,
                }}
                label="Fecha inicial de vigencia"
                placeholder="Fecha inicial de vigencia"
                minDate={new Date()}
                valueFormat="DD/MMM/YY"
              />
            </Grid.Col>
            <Grid.Col xs={12} md={6}>
              <DatePickerInput
                {...editForm.getInputProps("finalDate")}
                locale={utils.es}
                label="Fecha final de vigencia"
                placeholder="Fecha final de vigencia"
                classNames={{
                  label: classes.labelSecondary,
                  input: classes.input,
                  day: classes.calendar,
                  rightSection: classes.rightSection,
                }}
                minDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
                valueFormat="DD/MMM/YY"
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Flex
                gap={16}
                sx={(theme) => ({
                  [theme.fn.smallerThan("md")]: {
                    flexDirection: "column",
                  },
                })}
              >
                <Button
                  className={classes.buttonOutline}
                  fullWidth
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
                <Button
                  className={classes.button}
                  fullWidth
                  onClick={handleCreateEdit}
                >
                  Continuar
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        </GlobalCard>
      )}
    </>
  );
};

export default Form;
