import {
  Grid,
  Text,
  TextInput,
  ScrollArea,
  Button,
  Divider,
} from "@mantine/core";

const BeneficiariesCard = ({
  beneficiariesData,
  holderData,
  loadingBeneficiaries,
  GlobalCard,
  beneficiariesSelected,
  classes,
  utils,
  handleSelect,
}) => {
  return (
    <>
      {beneficiariesData.length > 0 &&
        holderData.length > 0 &&
        !loadingBeneficiaries && (
          <GlobalCard>
            <Text
              fw={700}
              size="xl"
              component="h3"
              color="#004236"
              mt={0}
              mb={16}
              ta="center"
            >
              Beneficiarios
            </Text>
            <ScrollArea
              h={
                beneficiariesSelected.length > 0
                  ? "auto"
                  : beneficiariesData.length >= 2
                  ? 400
                  : "auto"
              }
              sx={{
                '[data-orientation="horizontal"]': {
                  display: "none",
                },
              }}
            >
              {(beneficiariesSelected.length > 0
                ? beneficiariesSelected
                : beneficiariesData
              )
                .filter((item) => item.doc !== holderData[0].doc)
                .map((beneficiary, index) => (
                  <Grid key={index} columns={5}>
                    <Grid.Col span={5}>
                      <Text fw={700} size="lg">
                        {beneficiary.nombresApellidos}
                      </Text>
                    </Grid.Col>
                    <Grid.Col xs={5} md={1}>
                      <TextInput
                        classNames={{
                          input: classes.input,
                          label: classes.labelSecondary,
                        }}
                        label="Documento de identidad"
                        value={`${beneficiary.tipoDoc} ${beneficiary.doc}`}
                        disabled={true}
                      />
                    </Grid.Col>
                    <Grid.Col xs={5} md={1}>
                      <TextInput
                        classNames={{
                          input: classes.input,
                          label: classes.labelSecondary,
                        }}
                        label="Ciudad"
                        value={beneficiary.ciudad}
                        disabled={true}
                      />
                    </Grid.Col>
                    <Grid.Col xs={5} md={1}>
                      <TextInput
                        classNames={{
                          input: classes.input,
                          label: classes.labelSecondary,
                        }}
                        label="Fecha de Nacimiento"
                        value={utils.parseDateLocaleFormat(
                          beneficiary.fechaNac
                        )}
                        disabled={true}
                      />
                    </Grid.Col>
                    <Grid.Col xs={5} md={1}>
                      <TextInput
                        classNames={{
                          input: classes.input,
                          label: classes.labelSecondary,
                        }}
                        label="Género"
                        value={beneficiary.genero}
                        disabled={true}
                      />
                    </Grid.Col>
                    <Grid.Col xs={5} md={1}>
                      <TextInput
                        classNames={{
                          input: classes.input,
                          label: classes.labelSecondary,
                        }}
                        label="Edad"
                        value={`${utils.getAge(beneficiary.fechaNac)} años`}
                        disabled={true}
                      />
                    </Grid.Col>
                    <Grid.Col xs={5} offsetMd={4} md={1}>
                      <Button
                        className={classes.button}
                        fullWidth
                        onClick={() => handleSelect(beneficiary)}
                      >
                        Seleccionar
                      </Button>
                    </Grid.Col>
                    {index < beneficiariesData.length - 2 && (
                      <Grid.Col span={5}>
                        <Divider />
                      </Grid.Col>
                    )}
                  </Grid>
                ))}
            </ScrollArea>
          </GlobalCard>
        )}
    </>
  );
};

export default BeneficiariesCard;
