import { Grid, TextInput, Text, Button } from "@mantine/core";

const SeizureCard = ({
  seizureList,
  editData,
  GlobalCard,
  classes,
  utils,
  setEditData,
}) => {
  return (
    <>
      {seizureList.length > 0 && !editData && (
        <>
          <Text w={700} size="xl" component="h3" color="#004236">
            Demandantes
          </Text>
          {seizureList.map((item, index) => (
            <GlobalCard key={index}>
              <Grid>
                <Grid.Col xs={12} md={4}>
                  <TextInput
                    classNames={{
                      input: classes.input,
                      label: classes.labelSecondary,
                    }}
                    label="Nombre demandante"
                    value={item.nombreDemandante}
                    disabled={true}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={4}>
                  <TextInput
                    classNames={{
                      input: classes.input,
                      label: classes.labelSecondary,
                    }}
                    label="Número de documento"
                    value={item.documentoDemandante}
                    disabled={true}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={4}>
                  <TextInput
                    classNames={{
                      input: classes.input,
                      label: classes.labelSecondary,
                    }}
                    label="Cuenta"
                    value={item.cuentaBancaria}
                    disabled={true}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={4}>
                  <TextInput
                    classNames={{
                      input: classes.input,
                      label: classes.labelSecondary,
                    }}
                    label="Entidad"
                    value={item.entidadBancaria}
                    disabled={true}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={4}>
                  <TextInput
                    classNames={{
                      input: classes.input,
                      label: classes.labelSecondary,
                    }}
                    label="Número de radicado"
                    value={item.numRadicadoOficio}
                    disabled={true}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={4}>
                  <TextInput
                    classNames={{
                      input: classes.input,
                      label: classes.labelSecondary,
                    }}
                    label="Fecha de radicado"
                    value={utils.parseDateLocaleFormat(item.finVigenciaOficio)}
                    disabled={true}
                  />
                </Grid.Col>
                <Grid.Col xs={12} offsetMd={8} md={4}>
                  <Button
                    className={classes.button}
                    onClick={() => setEditData(item)}
                    fullWidth
                  >
                    Editar
                  </Button>
                </Grid.Col>
              </Grid>
            </GlobalCard>
          ))}
        </>
      )}
    </>
  );
};

export default SeizureCard;
