import { Text, Grid, TextInput, Button } from "@mantine/core";

const Search = ({ GlobalCard, form, handleSubmit, classes }) => {
  return (
    <>
      <Text fw={700} size={24} component="h1" color="#004236">
        Asociar embargo
      </Text>
      <GlobalCard>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Grid align="flex-end">
            <Grid.Col xs={12} md={10}>
              <TextInput
                {...form.getInputProps("register")}
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                label="Registro"
                placeholder="Ingrese el registro del titular"
              />
            </Grid.Col>
            <Grid.Col xs={12} md={2}>
              <Button
                type="submit"
                className={classes.button}
                fullWidth
                disabled={!form.isDirty("register")}
              >
                Buscar
              </Button>
            </Grid.Col>
          </Grid>
        </form>
      </GlobalCard>
    </>
  );
};

export default Search;
