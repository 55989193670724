import { GlobalCard, useStyles } from "@mfe/js-common-ave-uiutils";
import { Box, Text, Flex, LoadingOverlay, Loader } from "@mantine/core";
import { useForm } from "@mantine/form";

import {
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@mfe/react-common-ave-msal";
import {
  getAccessTokenApi,
  getAidHolderData,
  getAidBeneficiariesData,
  fetchSeizureListData,
  fetchUpdateCreateSeizureData,
  getAge,
  parseDateLocaleFormat,
  es,
} from "@mfe/ts-common-ave-utilitaries";
import { useEffect, useState } from "react";
import Search from "../components/search.component";
import HolderCard from "../components/holderCard.component";
import BeneficiariesCard from "../components/beneficiariesCard.component";
import SeizureCard from "../components/seizureCard.component";
import Form from "../components/form.component";

export default function OutpatientcareSeizure({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <OutpatientcareSeizureComponent organization={organization} />
    </MsalProvider>
  );
}

export const OutpatientcareSeizureComponent = ({ organization }) => {
  const isAuthenticated = useIsAuthenticated();
  const { classes } = useStyles();
  const { instance, accounts } = useMsal();
  const [tokenApi, setTokenApi] = useState("");
  const [holderData, setHolderData] = useState([]);
  const [beneficiariesData, setBeneficiariesData] = useState([]);
  const [beneficiariesSelected, setBeneficiariesSelected] = useState([]);
  const [loadingHolder, setLoadingHolder] = useState(null);
  const [loadingEdit, setLoadingEdit] = useState(null);
  const [loadingBeneficiaries, setLoadingBeneficiaries] = useState(false);
  const [editData, setEditData] = useState(null);
  const [error, setError] = useState(false);
  const [seizureList, setSeizureList] = useState([]);

  const form = useForm({
    initialValues: {
      register: "",
      alias: "",
      doc: "",
    },
  });

  const editForm = useForm({
    initialValues: {
      plaintiffName: "",
      id: "",
      bank: "",
      bankAccount: "",
      filingNumber: "",
      isActive: "no",
      initialDate: null,
      finalDate: null,
    },
  });

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    if (holderData.length > 0) {
      getAidBeneficiariesData(
        setBeneficiariesData,
        tokenApi,
        holderData[0].registro,
        setLoadingBeneficiaries
      );
    }
  }, [holderData]);

  useEffect(() => {
    if (editData) {
      editForm.setValues({
        id: editData.documentoDemandante,
        plaintiffName: editData.nombreDemandante,
        bank: editData.entidadBancaria,
        bankAccount: editData.cuentaBancaria,
        filingNumber: editData.numRadicadoOficio,
        initialDate: editData?.iniVigenciaOficio
          ? new Date(editData?.iniVigenciaOficio)
          : null,
        finalDate: editData?.finVigenciaOficio
          ? new Date(editData?.finVigenciaOficio)
          : null,
        isActive: editData.estado ? "yes" : "no",
      });
    }
  }, [editData]);

  useEffect(() => {
    if (loadingEdit === false)
      fetchSeizureListData(
        tokenApi,
        {
          holderDoc: holderData[0].doc,
          beneficiaryDoc: beneficiariesSelected[0].doc,
        },
        setSeizureList,
        setError
      );
  }, [loadingEdit]);

  const handleSubmit = (values) => {
    if (tokenApi)
      getAidHolderData(setHolderData, tokenApi, values, setLoadingHolder);
    editForm.reset();
    setEditData(null);
    setSeizureList([]);
    setBeneficiariesSelected([]);
    setError(false);
  };

  const handleSelect = (beneficiary) => {
    fetchSeizureListData(
      tokenApi,
      {
        holderDoc: holderData[0].doc,
        beneficiaryDoc: beneficiary.doc,
      },
      setSeizureList,
      setError
    );
    setBeneficiariesSelected([beneficiary]);
  };

  const handleCancel = () => {
    setEditData(null);
    setError(false);
    setBeneficiariesSelected([]);
  };

  const handleCreateEdit = () => {
    fetchUpdateCreateSeizureData(
      tokenApi,
      {
        ...editForm.values,
        holderDoc: holderData[0].doc,
        beneficiaryDoc: beneficiariesSelected[0].doc,
        holderName: holderData[0].nombresApellidos,
        beneficiaryName: beneficiariesSelected[0].nombresApellidos,
        holderCity: holderData[0].ciudad,
      },
      setLoadingEdit
    );
    setEditData(null);
    setSeizureList([]);
    setError(false);
  };

  const searchProps = {
    GlobalCard,
    classes,
    form,
    handleSubmit,
  };

  const holderCardProps = {
    holderData,
    GlobalCard,
    classes,
  };

  const beneficiariesCardProps = {
    beneficiariesData,
    holderData,
    loadingBeneficiaries,
    GlobalCard,
    beneficiariesSelected,
    classes,
    utils: { parseDateLocaleFormat, getAge },
    handleSelect,
  };

  const seizureCardProps = {
    seizureList,
    editData,
    GlobalCard,
    classes,
    utils: { parseDateLocaleFormat },
    setEditData,
  };

  const formProps = {
    editData,
    error,
    GlobalCard,
    editForm,
    classes,
    utils: { es },
    handleCancel,
    handleCreateEdit,
  };

  if (loadingHolder || loadingEdit) {
    return (
      <LoadingOverlay
        visible={loadingHolder || loadingEdit}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return isAuthenticated ? (
    <Box maw={{ xs: "100%", md: "80%" }} mx="auto" c="#004236" pb="3rem">
      <Search {...searchProps} />

      {loadingHolder === false && holderData.length === 0 && (
        <GlobalCard>
          <Text size="xl" color="#004236" my={0} ta="center">
            No se encontraron resultados, inténtelo nuevamente.
          </Text>
        </GlobalCard>
      )}

      <HolderCard {...holderCardProps} />

      {loadingBeneficiaries && (
        <GlobalCard>
          <Flex
            gap="md"
            justify="center"
            align="center"
            direction="row"
            wrap="wrap"
            h={200}
          >
            <Text size="md" fw={700}>
              Cargando beneficiarios de SAP
            </Text>
            <Loader variant="dots" color="#cbd300" />
          </Flex>
        </GlobalCard>
      )}

      <BeneficiariesCard {...beneficiariesCardProps} />

      <SeizureCard {...seizureCardProps} />

      <Form {...formProps} />
    </Box>
  ) : null;
};
